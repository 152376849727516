import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main } from '../../content/klient'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import LinkTile from '../../components/LinkTile'
import { sLinkTiles } from '../../style'
import FormSectionMessage from '../../sections/forms/FormSectionMessage'

const Klient = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/klient.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      wspolpraca: file(
        absolutePath: { regex: "/images/intros/wspolpraca.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      kontenery: file(
        absolutePath: { regex: "/images/intros/kontenery.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      palety: file(absolutePath: { regex: "/images/intros/palety.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      dokumenty: file(
        absolutePath: { regex: "/images/intros/dokumenty.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      faq: file(absolutePath: { regex: "/images/intros/faq.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      korektapaliwowa: file(
        absolutePath: { regex: "/images/intros/korekta-paliwowa.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Więcej informacji',
      en: 'More information',
      ua: 'БІЛЬШЕ ІНФОРМАЦІЇ',
    },
    action: 'SCROLL',
  }

  const links = [
    {
      slug: '/klient/kontenery/',
      title: {
        pl: 'Wymiary Kontenerów',
        en: 'Containers Dimensions',
        ua: 'Розміри контейнера',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.kontenery.childImageSharp,
    },
    {
      slug: '/klient/palety/',
      title: {
        pl: 'Rozmieszczenie Palet',
        en: 'Pallets Arrangement',
        ua: 'Розміщення піддонів',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.palety.childImageSharp,
    },
    {
      slug: '/klient/korekta-paliwowa/',
      title: {
        pl: 'Korekta Paliwowa',
        en: 'Fuel Surcharge',
        ua: 'Паливна корекція',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.korektapaliwowa.childImageSharp,
    },
    {
      slug: '/klient/wspolpraca/',
      title: {
        pl: 'Współpraca',
        en: 'Collaboration',
        ua: 'Співпраця',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.wspolpraca.childImageSharp,
    },
    {
      slug: '/klient/dokumenty/',
      title: {
        pl: 'Dokumenty',
        en: 'Documents',
        ua: 'Документи',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.dokumenty.childImageSharp,
    },
    {
      slug: '/klient/faq/',
      title: {
        pl: 'FAQ',
        en: 'FAQ',
        ua: 'FAQ',
      },
      subtitle: {
        pl: 'Klient',
        en: 'Client',
        ua: 'Клієнт',
      },
      image: query.faq.childImageSharp,
    },
  ].filter(
      (link) =>
      !(
        (lang === 'en' &&
        (
        // link.slug === '/klient/korekta-paliwowa/' ||
        link.slug === '/klient/faq/')
        ) ||

        (lang === 'ua' &&
        (link.slug === '/klient/korekta-paliwowa/' ||
        link.slug === '/klient/faq/')
        )
      )
    )

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro data={{ ...intro, button }} image={query.image.childImageSharp} />
      <Main h={1} title={main.title[lang]} desc={main.desc[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
      </Main>
      <FormSectionMessage />
    </Layout>
  )
}

export default Klient
